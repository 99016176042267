<template>
  <div class="superadmin-page">
    <BackTitle class="mb-32" confirm-click @click="goBack">
      Create venue
    </BackTitle>
    <VenueForm
      :value="venue"
      submitButtonText="Register"
      :isSubmitting="isSubmitting"
      :isIdEditable="true"
      @submit="save"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import VenueForm from "@/components/venues/VenueForm";
import { NEW_VENUE_MOCK } from "@/helpers/mocks";
import { mapActions } from "vuex";
import alert from "@/plugins/alert";
export default {
  name: "SuperadminCreatePage",
  components: { VenueForm, BackTitle },
  data() {
    return {
      isSubmitting: false,
      venue: { ...NEW_VENUE_MOCK },
    };
  },
  methods: {
    ...mapActions({
      createVenueForOwner: "superadmin/createVenueForOwner",
    }),
    async save({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.createVenueForOwner({ id, data });
        await this.goBack();
      } catch {
        alert.open({
          message: "An error has occurred. Probably the ID is already taken",
          variant: "danger",
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "Superadmin",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.superadmin-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
